import React, { useEffect } from "react";
import Nav from "../component/Nav";

export default function PivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <header>
        <Nav />
      </header>
      <main>
        <section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
              overflow: "hidden",
            }}
          >
            <img
              src={require("../images/truck 5.jpg")}
              alt=""
              style={{ objectFit: "cover" }}
            />
          </div>
        </section>
        <section>
          <div className="py-2 pb-10">
            <div className="pt-8 text-center flex justify-center">
              <h1 className="text-5xl  font-robotoflex pb-2 border-b-4 border-blue-700 ">
                J&S INSURANCE AGENCY INC. WEBSITE PRIVACY POLICY
              </h1>
            </div>
            <div className="text-center pt-12 px-5">
              <div>
                <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                  Last Updated 16/02/2024
                </h1>
              </div>
              <div>
                <p className=" font-cormorantgaramond">
                  J&S INSURANCE AGENCY INC. (“we” or “us” or “our”) respects the
                  privacy of our users (“user” or “you”). This Privacy Policy
                  explains how we collect, use, disclose, and safeguard your
                  information when you visit our website WWW.JSINSUR.COM
                  including any other media form, media channel, mobile website,
                  or mobile application related or connected thereto
                  (collectively, the “Site”). Please read this privacy policy
                  carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY
                  POLICY, PLEASE DO NOT ACCESS THE SITE. We reserve the right to
                  make changes to this Privacy Policy at any time and for any
                  reason. We will alert you about any changes by updating the
                  “Last Updated” date of this Privacy Policy. Any changes or
                  modifications will be effective immediately upon posting the
                  updated Privacy Policy on the Site, and you waive the right to
                  receive specific notice of each such change or modification.
                  You are encouraged to periodically review this Privacy Policy
                  to stay informed of updates. You will be deemed to have been
                  made aware of, will be subject to, and will be deemed to have
                  accepted the changes in any revised Privacy Policy by your
                  continued use of the Site after the date such revised Privacy
                  Policy is posted.
                </p>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    COLLECTION OF YOUR INFORMATION
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We may collect information about you in a variety of ways.
                    The information we may collect on the Site includes:
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Personal Data
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Personally identifiable information, such as your name,
                    shipping address, email address, and telephone number, and
                    demographic information, such as your age, gender, hometown,
                    and interests, that you voluntarily give to us when you
                    choose to participate in various activities related to the
                    Site., such as online chat and message boards. You are under
                    no obligation to provide us with personal information of any
                    kind, however your refusal to do so may prevent you from
                    using certain features of the Site.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Derivative Data
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Information our servers automatically collect when you
                    access the Site, such as your IP address, your browser type,
                    your operating system, your access times, and the pages you
                    have viewed directly before and after accessing the Site.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Financial Data
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Financial information, such as data related to your payment
                    method (e.g. valid credit card number, card brand,
                    expiration date) that we may collect when you purchase,
                    order, return, exchange, or request information about our
                    services from the Site. We store only very limited, if any,
                    financial information that we collect. Otherwise, all
                    financial information is stored by our payment processor,
                    Stripe, and you are encouraged to review their privacy
                    policy and contact them directly for responses to your
                    questions.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Facebook Permissions
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    The Site may by default access your Facebook basic account
                    information, including your name, email, gender, birthday,
                    current city, and profile picture URL, as well as other
                    information that you choose to make public. We may also
                    request access to other permissions related to your account,
                    such as friends, checkins, and likes, and you may choose to
                    grant or deny us access to each individual permission. For
                    more information regarding Facebook permissions, refer to
                    the Facebook Permissions Reference page.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Data From Social Networks
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    User information from social networking sites, such as
                    Apple’s Game Center, Facebook, Google+, Instagram,
                    Pinterest, Twitter, including your name, your social network
                    username, location, gender, birth date, email address,
                    profile picture, and public data for contacts, if you
                    connect your account to such social networks.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Mobile Device Data
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Device information, such as your mobile device ID, model,
                    and manufacturer, and information about the location of your
                    device, if you access the Site from a mobile device.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Third-Party Data
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Information from third parties, such as personal information
                    or network friends, if you connect your account to the third
                    party and grant the Site permission to access this
                    information.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Data From Contests, Giveaways, and Surveys
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Personal and other information you may provide when entering
                    contests or giveaways and/or responding to surveys.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    USE OF YOUR INFORMATION
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Having accurate information about you permits us to provide
                    you with a smooth, efficient, and customized experience.
                    Specifically, we may use information collected about you via
                    the Site to:
                  </p>
                </div>
              </div>
              {/* pointer  */}
              <div className="pt-10">
                <div className=" font-cormorantgaramond text-start">
                  <li className="pt-2">
                    Increase the efficiency and operation of the Site.
                  </li>
                  <li className="pt-2">
                    Monitor and analyze usage and trends to improve your
                    experience with the Site.
                  </li>
                  <li className="pt-2">Notify you of updates to the Site.</li>
                  <li className="pt-2">
                    Offer new products, services, and/or recommendations to you.
                  </li>
                  <li className="pt-2">
                    Perform other business activities as needed.
                  </li>
                  <li className="pt-2">
                    Prevent fraudulent transactions, monitor against theft, and
                    protect against criminal activity.
                  </li>
                  <li className="pt-2">Process payments and refunds.</li>
                  <li className="pt-2">
                    Request feedback and contact you about your use of the Site.
                  </li>
                  <li className="pt-2">
                    Resolve disputes and troubleshoot problems.
                  </li>
                  <li className="pt-2">
                    Respond to product and customer service requests.
                  </li>
                  <li className="pt-2">Send you a newsletter.</li>
                  <li className="pt-2">Solicit support for the Site.</li>
                </div>
              </div>
              {/* //  */}
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    DISCLOSURE OF YOUR INFORMATION
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We may share information we have collected about you in
                    certain situations. Your information may be disclosed as
                    follows:
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    By Law or to Protect Rights
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    If we believe the release of information about you is
                    necessary to respond to legal process, to investigate or
                    remedy potential violations of our policies, or to protect
                    the rights, property, and safety of others, we may share
                    your information as permitted or required by any applicable
                    law, rule, or regulation. This includes exchanging
                    information with other entities for fraud protection and
                    credit risk reduction.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Third-Party Service Providers
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We may share your information with third parties that
                    perform services for us or on our behalf, including payment
                    processing, data analysis, email delivery, hosting services,
                    customer service, and marketing assistance.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Marketing Communications
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    With your consent, or with an opportunity for you to
                    withdraw consent, we may share your information with third
                    parties for marketing purposes, as permitted by law.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Interactions with Other Users
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    If you interact with other users of the Site those users may
                    see your name, profile photo, and descriptions of your
                    activity, including sending invitations to other users,
                    chatting with other users, liking posts, following blogs.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Online Postings
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    When you post comments, contributions or other content to
                    the Site.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Third-Party Advertisers
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We may use third-party advertising companies to serve ads
                    when you visit the Site or our mobile application. These
                    companies may use information about your visits to the Site
                    and other websites that are contained in web cookies in
                    order to provide advertisements about goods and services of
                    interest to you.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Affiliates
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We may share your information with our affiliates, in which
                    case we will require those affiliates to honor this Privacy
                    Policy. Affiliates include our parent company and any
                    subsidiaries, joint venture partners or other companies that
                    we control or that are under common control with us.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Business Partners
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We may share your information with our business partners to
                    offer you certain products, services or promotions.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Offer Wall
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Our mobile application may display a third-party hosted
                    “offer wall.” Such an offer wall allows third-party
                    advertisers to offer virtual currency, gifts, or other items
                    to users in return for acceptance and completion of an
                    advertisement offer. Such an offer wall may appear in our
                    mobile application and be displayed to you based on certain
                    data, such as your geographic area or demographic
                    information. When you click on an offer wall, you will leave
                    our mobile application. A unique identifier, such as your
                    user ID, will be shared with the offer wall provider in
                    order to prevent fraud and properly credit your account.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Social Media Contacts If you connect to the Site. Other
                    Third Parties
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We may share your information with advertisers and investors
                    for the purpose of conducting general business analysis. We
                    may also share your information with such third parties for
                    marketing purposes, as permitted by law.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Sale or Bankruptcy
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    If we reorganize or sell all or a portion of our assets,
                    undergo a merger, or are acquired by another entity, we may
                    transfer your information to the successor entity. If we go
                    out of business or enter bankruptcy, your information would
                    be an asset transferred or acquired by a third party. You
                    acknowledge that such transfers may occur and that the
                    transferee may decline honor commitments we made in this
                    Privacy Policy. We are not responsible for the actions of
                    third parties with whom you share personal or sensitive
                    data, and we have no authority to manage or control
                    third-party solicitations. If you no longer wish to receive
                    correspondence, emails or other communications from third
                    parties, you are responsible for contacting the third party
                    directly.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    TRACKING TECHNOLOGIES
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond"></p>
                </div>
              </div>
              {/* ///  */}
              <div className="pt-10">
                <p className=" font-cormorantgaramond">
                  Cookies and Web Beacons We may use cookies, web beacons,
                  tracking pixels, and other tracking technologies on the Site.
                  to help customize the Site. and improve your experience. When
                  you access the Site or our mobile application, your personal
                  information is not collected through the use of tracking
                  technology. Most browsers are set to accept cookies by
                  default. You can remove or reject cookies, but be aware that
                  such action could affect the availability and functionality of
                  the Site [or our mobile application]. You may not decline web
                  beacons. However, they can be rendered ineffective by
                  declining all cookies or by modifying your web browser’s
                  settings to notify you each time a cookie is tendered,
                  permitting you to accept or decline cookies on an individual
                  basis. We may use cookies, web beacons, tracking pixels, and
                  other tracking technologies on the Site. To help customize the
                  Site and improve your experience. For more information on how
                  we use cookies, please refer to our Cookie Policy posted on
                  the Site, which is incorporated into this Privacy Policy. By
                  using the Site, you agree to be bound by our Cookie Policy.
                </p>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Internet-Based Advertising
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Additionally, we may use third-party software to serve ads
                    on the Site., implement email marketing campaigns, and
                    manage other interactive marketing initiatives. This
                    third-party software may use cookies or similar tracking
                    technology to help manage and optimize your online
                    experience with us. For more information about opting-out of
                    interest-based ads, visit the Network Advertising Initiative
                    Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    Website Analytics
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We may also partner with selected third-party vendors[, such
                    as [Adobe Analytics, [Clicktale], [Clicky], [Cloudfare,]
                    [Crazy Egg], [Flurry Analytics], [Google Analytics], [Heap
                    Analytics], [Inspectlet], [Kissmetrics], [Mixpanel],
                    [Piwik], and others, to allow tracking technologies and
                    remarketing services on the Site. Through the use of first
                    party cookies and third-party cookies, to, among other
                    things, analyze and track users’ use of the Site. ,
                    determine the popularity of certain content and better
                    understand online activity. By accessing the Site, [or our
                    mobile application], you consent to the collection and use
                    of your information by these third-party vendors. You are
                    encouraged to review their privacy policy and contact them
                    directly for responses to your questions. We do not transfer
                    personal information to these third-party vendors. However,
                    if you do not want any information to be collected and used
                    by tracking technologies, you can visit the third-party
                    vendor or the Network Advertising Initiative Opt-Out Tool or
                    Digital Advertising Alliance Opt-Out Tool. You should be
                    aware that getting a new computer, installing a new browser,
                    upgrading an existing browser, or erasing or otherwise
                    altering your browser’s cookies files may also clear certain
                    opt-out cookies, plug-ins, or settings.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    THIRD-PARTY WEBSITES
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    The Site. may contain links to third-party websites and
                    applications of interest, including advertisements and
                    external services, that are not affiliated with us. Once you
                    have used these links to leave the Site [or our mobile
                    application], any information you provide to these third
                    parties is not covered by this Privacy Policy, and we cannot
                    guarantee the safety and privacy of your information. Before
                    visiting and providing any information to any third-party
                    websites, you should inform yourself of the privacy policies
                    and practices (if any) of the third party. responsible for
                    that website, and should take those steps necessary to, in
                    your discretion, protect the privacy of your information. We
                    are not responsible for the content or privacy and security
                    practices and policies of any third parties, including other
                    sites, services or applications that may be linked to or
                    from the Site [or our mobile application.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    SECURITY OF YOUR INFORMATION
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We use administrative, technical, and physical security
                    measures to help protect your personal information. While we
                    have taken reasonable steps to secure the personal
                    information you provide to us, please be aware that despite
                    our efforts, no security measures are perfect or
                    impenetrable, and no method of data transmission can be
                    guaranteed against any interception or other type of misuse.
                    Any information disclosed online is vulnerable to
                    interception and misuse by unauthorized parties. Therefore,
                    we cannot guarantee complete security if you provide
                    personal information.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    POLICY FOR CHILDREN
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    We do not knowingly solicit information from or market to
                    children under the age of 13. If you become aware of any
                    data we have collected from children under age 13, please
                    contact us using the contact information provided below.
                  </p>
                </div>
              </div>
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    CONTROLS FOR DO-NOT-TRACK FEATURES
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    Most web browsers and some mobile operating systems [and our
                    mobile applications include a Do-Not-Track (“DNT”) feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. No uniform technology
                    standard for recognizing and implementing DNT signals has
                    been finalized. As such, we do not currently respond to DNT
                    browser signals or any other mechanism that automatically
                    communicates your choice not to be tracked online. If a
                    standard for online tracking is adopted that we must follow
                    in the future, we will inform you about that practice in a
                    revised version of this Privacy Policy. Most web browsers
                    and some mobile operating systems [and our mobile
                    applications] include a Do-Not-Track (“DNT”) feature or
                    setting you can activate to signal your privacy preference
                    not to have data about your online browsing activities
                    monitored and collected. If you set the DNT signal on your
                    browser, we will respond to such DNT browser signals.
                  </p>
                </div>
              </div>
              {/* list li start here  */}
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    OPTIONS REGARDING YOUR INFORMATION
                  </h1>
                </div>
                <div>
                  <p className="font-cormorantgaramond text-2xl text-left py-5">
                    Account Information.
                  </p>
                  <h1 className=" font-cormorantgaramond text-1xl text-left py-5">
                    You may at any time review or change the information in your
                    account or terminate your account by:
                  </h1>
                </div>
                <div className="pt-2 font-cormorantgaramond">
                  <li>
                    Logging into your account settings and updating your
                    account.
                  </li>
                  <li>
                    Contacting us using the contact information provided below.
                  </li>
                  <li>
                    Emails and Communications If you no longer wish to receive
                    correspondence, emails, or other communications from us, you
                    may opt-out by:
                  </li>
                  <li>
                    Noting your preferences at the time you register your
                    account with the Site [or our mobile application].
                  </li>
                  <li>
                    Logging into your account settings and updating your
                    preferences.
                  </li>
                  <li>
                    Contacting us using the contact information provided below.
                  </li>
                  <h1 className="">
                    If you no longer wish to receive correspondence, emails, or
                    other communications from third parties, you are responsible
                    for contacting the third party directly.
                  </h1>
                </div>
              </div>
              {/* list li end here  */}
              <div className="pt-10 text-left">
                <div>
                  <h1 className=" font-cormorantgaramond text-4xl text-left py-5">
                    CALIFORNIA PRIVACY RIGHTS
                  </h1>
                </div>
                <div>
                  <p className=" font-cormorantgaramond">
                    California Civil Code Section 1798.83, also known as the
                    “Shine The Light” law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below. If you are under 18
                    years of age, reside in California, and have a registered
                    account with the Site [or our mobile application], you have
                    the right to request removal of unwanted data that you
                    publicly post on the Site [or our mobile application]. To
                    request removal of such data, please contact us using the
                    contact information provided below, and include the email
                    address associated with your account and a statement that
                    you reside in California. We will make sure the data is not
                    publicly displayed on the Site [or our mobile application,
                    but please be aware that the data may not be completely or
                    comprehensively removed from our systems
                  </p>
                </div>
              </div>
              <div className="text-left flex flex-col justify-start font-cormorantgaramond pt-5">
                <h1 className="text-2xl text-gray-600 font-semibold">
                  CONTACT US
                </h1>
                <h2 className="py-4 ">
                  If you have questions or comments about this Privacy Policy,
                  please contact us at:
                </h2>
                <h3 className="pt-4 font-semibold">
                  J&S INSURANCE AGENCY INC.
                </h3>
                <h3 className="pb-4">DBA Jay S Insurance Agency</h3>
                <h4>
                  {" "}
                  <span className="font-semibold">Location:</span> Fresno
                  California - USA
                </h4>
                <h4>
                  {" "}
                  <span className="font-semibold">Email: </span>{" "}
                  sandy@jsinsur.com
                </h4>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
